import React, {FC, useState} from "react";
import BNewPagination from "../../components/b-pagination_new";
import {Button} from "react-bootstrap";
import TransactionPrivateTagModal from "./utils/TransactionPrivateTagModal";
import TransactionPrivateTags from "./utils/TransactionPrivateTags";
const TxPrivateTags: FC = () => {
    const [pagination, setPagination] = useState<any>({
        limit: 50,
        page: 1,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="bs-card mb-4">
            <div className="bs-card-header d-flex justify-content-between align-items-center">
                <h4 className="bs-card-header-title">
                    Transaction Private Tags &nbsp;
                    <span className="badge bg-secondary bg-opacity-10 border border-secondary border-opacity-25 text-dark fw-medium rounded-pill">
                        10000 limit
                    </span>
                </h4>
                <button className="btn btn-sm btn-primary" onClick={openModal}>
                    <i className="bi bi-plus me-1"></i> Add
                </button>
            </div>
            <div className="bs-card-body">
                <div className="p-2">
                    <p className="mb-4">
                        A private transaction note (up to 100 characters) can be saved and is useful for transaction tracking.
                    </p>
                    <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-3">
                        <div className="d-flex align-items-center gap-2">
                            <span className="text-muted">&nbsp;0 notes added</span>
                        </div>
                        <div className="d-flex gap-2">
                            <div className="dropdown order-md-2">
                                <button className="btn btn-sm btn-secondary" type="button" id="dropdownSearchFilter" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-search fa-fw"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownSearchFilter" style={{minWidth: '10rem'}}>
                                    <div className="input-group" style={{minWidth: '22rem'}}>
                                        <form action="/mynotes_address" method="get" className="js-focus-state input-group input-group-sm w-100" autoComplete="off">
                                            <input className="form-control py-2" id="q" name="q" type="search" placeholder="Search Address or Tag" maxLength={50}/>
                                            <Button type="submit">Find</Button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="bs-table">
                    <thead>
                    <tr className="bs-table-row bs-table-header">
                        <th className="bs-table-cell">
                            <div className="d-flex bs-cp">Transaction</div>
                        </th>
                    </tr>
                    <tr className="border-bottom-0">
                            <td colSpan={10}>
                                <TransactionPrivateTags />
                            {/* <div className="text-center py-24">
                                <div className="mb-4 mt-4">
                                    <span className="bg-warning bg-opacity-10 border border-warning border-opacity-25 text-yellow-600 fs-70x content-center rounded-pill mx-auto"
                                          style={{width: "4rem", height: "4rem"}}>
                                        <i className="bi bi-inboxes fs-4"></i></span></div>
                                <h3 className="h5">There are no private notes found</h3>
                                <p className="text-muted mb-0">Please try again later</p></div> */}
                        </td>
                    </tr>
                    </thead>
                </table>
            </div>
            <div className="bs-card-footer p-3">
                <BNewPagination pagination={pagination} totalRecords={50} onPagination={setPagination}/>
            </div>
            <TransactionPrivateTagModal show={isModalOpen} onClose={closeModal} />
        </div>
    );
};

export default TxPrivateTags;
