import { useEffect, useState } from "react";
import { ethers } from "ethers";
import Form from "./Form";
import { FormStateProps, ErrorState } from "../common/tokenDeployer/types";
import { deployToken } from "../common/tokenDeployer/utils";
import { toast } from "react-toastify";
import WalletInfo from "../common/metamask/support-files/contractConnectedWallet";
import BCopy from "../../components/b-copy";
import { addressService } from "../../store/actions";
import { verifyContract } from "../common/tokenDeployer/utils/verifyContract";
import { ERC20 } from "../common/tokenDeployer/contracts/erc20/ERC20";

export default function ERC20Deployer() {
  const [formState, setFormState] = useState<FormStateProps>({
    name: "",
    symbol: "",
    supply: "",
  });

  const [isError, setIsError] = useState<ErrorState>({
    error: false,
    message: "",
    type: "",
  });

  const [walletAddress, setWalletAddress] = useState<string>("");


  const handleSubmit = async () => {
    const { name, symbol, supply } = formState;

    if (!name) {
      setIsError({
        error: true,
        message: "Token name is required.",
        type: "name",
      });
      return;
    }
    if (!symbol) {
      setIsError({
        error: true,
        message: "Token symbol is required.",
        type: "symbol",
      });
      return;
    }
    if (!supply) {
      setIsError({
        error: true,
        message: "Token supply is required.",
        type: "supply",
      });
      return;
    }

    setIsError({ error: false, message: "", type: "" });

    try {
      if (!window.ethereum) {
        throw new Error("MetaMask not installed");
      }

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      const loadingToast = toast.loading(
        `Deploying ${name} with initial supply ${supply}`
      );

      const contract = await deployToken({ name, symbol, supply, signer });
      const deployTx = contract.deploymentTransaction();

      if (!deployTx) {
        throw new Error("Deployment transaction not found");
      }

      // Wait for the deployment transaction to be mined
      await deployTx.wait();

      // Submit contract for verification
      await verifyContract(contract.target.toString(), ERC20);

      toast.dismiss(loadingToast);
      toast.success(
        <div>
          <p>{name} deployed successfully!</p>
          <a
            href={`/tx/${deployTx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#3b82f6", textDecoration: "underline" }}
          >
            View in Explorer
          </a>
          <p>
            Contract Address: {contract.target.toString()}{" "}
            <BCopy value={contract.target.toString()} />
          </p>
        </div>
      );
    } catch (error: any) {
      toast.error(error.reason || error.message || "An unknown error occurred");
    }
  };

  return (
    <div className="td-container">
      <header className="td-header">
        <h1 className="td-title">Token Deployer</h1>
        <p className="td-subtitle">
          Easily deploy your ERC20 token on {process.env.REACT_APP_BASE_UNIT}{" "}
          Network.
        </p>
      </header>

      <section className="td-main-section">
        <WalletInfo />
        <Form
          isError={isError}
          formState={formState}
          setFormState={setFormState}
          walletAddress={walletAddress}
        />
        <button onClick={handleSubmit} className="td-deploy-button">
          Deploy
        </button>
      </section>

      <section className="td-info-section">
        <h2 className="td-section-title">The ERC20 Contract</h2>
        <p className="td-section-text">
          This token deployer creates a standard ERC20 token using
          OpenZeppelin's implementation. The deployed contract includes basic
          token functionality such as transfers, approvals, and allowances. The
          entire initial supply will be minted to the deployer's address.
        </p>
      </section>
    </div>
  );
}
