import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transactionService } from '../../store/actions';
import { Button } from 'react-bootstrap';
import BCopy from "../../components/b-copy";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface DecodedParameter {
  name: string;
  type: string;
  value: string | number | any[];
}

interface DecodedInput {
  method_call: string;
  method_id: string;
  parameters: DecodedParameter[];
}

interface TransactionResponse {
  decoded_input: DecodedInput | null | [];
  message?: string;
  data?: Record<string, any>;
}

const InputDataDecoder = () => {
  const { t } = useTranslation();
  const [txHash, setTxHash] = useState<string>('');
  const [decodedData, setDecodedData] = useState<DecodedInput | null>(null);
  const [inputError, setInputError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showErrorToast = (message: string) => {
    toast(message, {
      type: 'error',
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  const showSuccessToast = (message: string) => {
    toast(message, {
      type: 'success',
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  const getTxDetails = async (hash: string) => {
    setIsLoading(true);
    setDecodedData(null);

    try {
      const response: TransactionResponse = await transactionService.getTransaction(hash);
      
      if (response?.message === "Transaction not found.") {
        showErrorToast(t('Transaction not found'));
        return;
      }

      if (!response?.decoded_input || 
          (Array.isArray(response.decoded_input) && response.decoded_input.length === 0)) {
        showErrorToast(t('No decoded data available for this transaction'));
        return;
      }

      setDecodedData(response.decoded_input as DecodedInput);
      showSuccessToast(t('Transaction decoded successfully'));
      
    } catch (error) {
      showErrorToast(t('Error fetching transaction details. Please try again.'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setInputError(null);
    
    if (!txHash) {
      setInputError(t('Please enter a transaction hash'));
      return;
    }

    if (!txHash.startsWith('0x')) {
      setInputError(t('Transaction hash must start with 0x'));
      return;
    }

    getTxDetails(txHash);
  };

  const handleReset = () => {
    setTxHash('');
    setDecodedData(null);
    setInputError(null);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    showSuccessToast(t('Copied to clipboard'));
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="bs-page-title">
            {t('Input Data Decoder')}
            <span className="bs-page-title-secondary">
              {t('Decode transaction input data using ABI and Tx Hash.')}
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="bs-card">
            <div className="bs-card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12">
                  <label htmlFor="txHash" className="form-label">
                    {t('Transaction Hash')}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${inputError ? 'is-invalid' : ''}`}
                    id="txHash"
                    placeholder="0x..."
                    value={txHash}
                    onChange={(e) => {
                      setTxHash(e.target.value);
                      setInputError(null);
                    }}
                  />
                  {inputError && (
                    <div className="invalid-feedback">{inputError}</div>
                  )}
                </div>
                <div className="col-12">
                  <Button 
                    type="submit" 
                    disabled={isLoading}
                  >
                    {isLoading ? t('Decoding...') : t('Decode')}
                  </Button>
                  <button
                    type="button"
                    className="btn btn-light ms-3"
                    onClick={handleReset}
                    disabled={isLoading}
                  >
                    {t('Reset')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {decodedData && (
        <div className="row">
          <div className="col-lg-12">
            <div className="bs-card">
              <div className="bs-card-body">
                <div className="decoded-results">
                  <div className="decoded-results-header">
                    {t('Decoded Results:')} <span className="result-count">1 result found</span>
                  </div>

                  <div className="decoded-results-warning">
                    <i className="bi bi-info-circle"></i>
                    <span>{t('Displaying functions that fit the entered input data, results below may not necessarily match the actual executed contract interaction.')}</span>
                  </div>

                  <div className="decoded-results-content">
                    <div className="function-section">
                      <span className="function-label">Function</span>
                      <span className="function-badge">
                        {decodedData.method_call.split('(')[0]}
                      </span>
                    </div>

                    {decodedData.parameters.map((param, index) => (
                      <div key={index} className="parameter-section">
                        <div className="param-header">
                          <span className="param-type">{param.type}</span>
                          <span className="param-name">{param.name}</span>
                        </div>
                        <div className="param-value">
                          {JSON.stringify(param.value)}
                          <BCopy value={JSON.stringify(param.value)} />
                        </div>
                      </div>
                    ))}

                    <button
                      className="copy-all-btn"
                      onClick={() => copyToClipboard(JSON.stringify(decodedData.parameters))}
                    >
                      {t('Copy All Parameters')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InputDataDecoder;