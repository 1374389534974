import React, {useEffect, useState} from "react";
import ChartGasPrice from "../resources/charts-stats/chart-gas-price";
import ChartGasPriceHeatMap from "../resources/charts-stats/chart-gas-price-heatmap";
import {gasService} from "../../store/actions";

const GasDetails = () => {
    const [loading, setLoading] = useState(true);
    const [gasTracker, setGasTrackerList] = useState<any>(null);

    const getGasSectionList = async () => {
        try {
            setLoading(true);
            gasService
                .getGasSection()
                .then((response: any) => {
                    setGasTrackerList(response);
                    setLoading(false);
                })
                .catch((e) => setLoading(false));
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getGasSectionList();
    }, []);
    return (
        <div className="container">
            <section
                id="wave-bg"
                className="bg-light py-12 pb-36"
                style={{
                    backgroundImage: "url(https://etherscan.io/assets/svg/patterns/waves.svg)",
                }}
            >
                <div className="container-xxl pb-2">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-lg-6 text-center">
                            <h1 className="h4">
                                {process.env.REACT_APP_BASE_NAME} Gas Tracker
                                <span className="small">⛽</span>
                            </h1>
                            <div className="col-9 mx-auto">
                                Sponsored by: <img width="20" src="https://etherscan.io/images/gen/cons_20.png" alt="ads"/>
                                <a href="https://metamask.io/" rel="nofollow" title="Links to an External Advertiser site" target="_blank">
                                    {" "}
                                    <b>MetaMask</b>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-xxl pt-8 mt-n36">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                    <div className="fw-medium">
                        Next update in{" "}
                        <span className="text-primary" id="countdown">
                            10s
                        </span>
                    </div>
                    <div className="d-flex align-items-center content-center gap-1">
                        <a
                            className="btn btn-sm btn-white content-center rounded-pill"
                            target="_blank"
                            href="https://docs.etherscan.io/api-endpoints/gas-tracker"
                        >
                            <i className="fa fa-code me-1"></i>Gas APIs
                        </a>
                        <a
                            className="d-none d-sm-inline-block btn btn-sm btn-white content-center rounded-pill"
                            target="_blank"
                            href="https://chrome.google.com/webstore/detail/etherscan/joeoaocmnapjmkhjndfflecmdaldkpbn"
                        >
                            <img className="me-1" width="16" src="https://etherscan.io/assets/svg/clients-logo/chrome.svg" alt=""/>
                            Install Gas Extension
                        </a>
                    </div>
                </div>

                <div className="row g-3 mb-4">
                    <div className="col-md-6 col-lg order-lg-4" style={{minHeight: "9rem"}}>
                        <a
                            className="bs-card h-100 relative bg-gradient-card text-dark group"
                            href="https://goto.etherscan.com/rd/XURQMW6TQS6NEQTFKBF97NPJC"
                            style={{
                                backgroundImage: "linear-gradient(rgba(242,149,61, 0.15) 0%, rgba(255, 255, 255, 0) 5rem)",
                            }}
                        >
                            <span className="position-absolute bg-white text-dark shadow-sm rounded-1 small px-1" style={{right: ".75rem", top: "-.5rem"}}>
                                Ad
                            </span>
                            <div className="bs-card-body d-flex justify-content-center">
                                <div className="my-auto text-center">
                                    <div className="content-center gap-1 mb-3">
                                        <img id="sponsor-logo" width="20" src="https://etherscan.io/images/gen/cons_20.png" alt="Ad"/>
                                        <div className="h6 mb-0">MetaMask</div>
                                    </div>
                                    <p className="text-muted mb-2">Swap from Anywhere, Anytime - with MetaMask</p>
                                    <span className="fw-medium text-primary group-text-underline">Swap Now</span>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6 col-lg">
                        <div className="bs-card h-100" id="divLow">
                            <div className="bs-card-body d-flex flex-column text-center gap-3">
                                <h3 className="h6 d-flex align-items-center justify-content-center gap-1 mb-0">
                                    <img src="https://etherscan.io/images/smile3.png" className="me-1 mb-1" width="20px"/> Low
                                </h3>
                                <div id="divLowPrice">
                                    <div className="d-flex flex-column text-center gap-3">
                                        <div className="h4 text-success mb-0">
                                            <span id="spanLowPrice">{gasTracker?.gas_prices?.slow}</span> gwei
                                        </div>
                                        <div className="text-muted">
                                            <span id="spanLowPriorityAndBase">Base: 21 | Priority: 1</span>
                                            <br/>
                                            $1.03 |{" "}
                                            <span data-bs-trigger="hover" data-bs-toggle="tooltip">
                                                ~ 3 mins<strong>: </strong>0 secs
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg">
                        <div className="bs-card h-100">
                            <div className="bs-card-body d-flex flex-column text-center gap-3">
                                <h3 className="h6 d-flex align-items-center justify-content-center gap-1 mb-0">
                                    <img src="https://etherscan.io/images/smile2.png" className="me-1 mb-1" width="20px"/> Average
                                </h3>
                                <div id="divAvgPrice">
                                    <div className="d-flex flex-column text-center gap-3">
                                        <div className="h4 text-primary mb-0">
                                            <span id="spanAvgPrice">{gasTracker?.gas_prices?.average}</span> gwei
                                        </div>
                                        <div className="text-muted">
                                            <span id="spanProposePriorityAndBase">Base: 21 | Priority: 1</span>
                                            <br/>
                                            $1.03 |{" "}
                                            <span data-bs-trigger="hover" data-bs-toggle="tooltip">
                                                ~ 3 mins<strong>: </strong>0 secs
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg">
                        <div className="bs-card h-100">
                            <div className="bs-card-body d-flex flex-column text-center gap-3">
                                <h3 className="h6 d-flex align-items-center justify-content-center gap-1 mb-0">
                                    <img src="https://etherscan.io/images/smile1.png" className="me-1 mb-1" width="20px"/> High
                                </h3>
                                <div id="divHighPrice">
                                    <div className="d-flex flex-column text-center gap-3">
                                        <div className="h4 text-danger mb-0">
                                            {/* <font color="brown"><span id="spanHighPrice">25</span> gwei</font> */}
                                            <span style={{color: "brown"}}>
                                                <span id="spanHighPrice">{gasTracker?.gas_prices?.fast}</span> gwei
                                            </span>
                                        </div>
                                        <div className="text-muted">
                                            <span id="spanHighPriorityAndBase">Base: 21 | Priority: 4</span>
                                            <br/>
                                            $1.14 |{" "}
                                            <span data-bs-trigger="hover" data-bs-toggle="tooltip">
                                                ~ 30 secs
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-xxl pb-16">
                <div className="row g-4 mb-4">
                    <div className="col-12 order-lg-3">
                        <div className="d-flex justify-content-center">
                            <a
                                className="position-relative d-inline-block d-sm-none"
                                href="https://goto.etherscan.com/rd/9Y3TEXS6GYNA4CJGVXPY3WFT3"
                                rel="nofollow"
                                title="Links to an External Advertiser site"
                                target="_blank"
                            >
                                <span className="position-absolute bg-white text-dark shadow-sm rounded-1 small px-1" style={{right: ".75rem", top: "-.5rem"}}>
                                    Ad
                                </span>
                                <img className="img-fluid rounded" width="321" height="101" src="https://etherscan.io/images/gen/cons_gt_321x101.png" alt=""/>
                            </a>
                            <a
                                className="position-relative d-none d-sm-inline-block d-lg-none"
                                href="https://goto.etherscan.com/rd/JSE6FEE88J2G16RDNMWU7CE52"
                                rel="nofollow"
                                title="Links to an External Advertiser site"
                                target="_blank"
                            >
                                <span className="position-absolute bg-white text-dark shadow-sm rounded-1 small px-1" style={{right: ".75rem", top: "-.5rem"}}>
                                    Ad
                                </span>
                                <img className="img-fluid rounded" width="728" height="90" src="https://etherscan.io/images/gen/cons_gt_730x90.png" alt=""/>
                            </a>
                            {/* <a
                className="position-relative d-none d-lg-inline-block"
                href="https://goto.etherscan.com/rd/5D6VJITX69MCRTWUXS2FDCJCC"
                rel="nofollow"
                title="Links to an External Advertiser site"
                target="_blank"
              >
                <span
                  className="position-absolute bg-white text-dark shadow-sm rounded-1 small px-1"
                  style={{ right: ".75rem", top: "-.5rem" }}
                >
                  Ad
                </span>
                <img
                  className="img-fluid rounded"
                  src="https://etherscan.io/images/gen/cons_gt_1140x90.png"
                  alt=""
                />
              </a> */}
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="bs-card h-100">
                            <div className="bs-card-body">
                                <div className="mb-3">
                                    <h2 className="fs-base fw-medium">Additional Info</h2>
                                    <div className="row g-2 mb-2">
                                        <div className="col-6 col-xxl-3">
                                            <div className="h-100 bg-light rounded p-3">
                                                <h3 className="text-cap text-muted mb-1.5">Last Block</h3>
                                                <div className="fw-medium lh-1">
                                                    <span id="lastblock">19040299</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-xxl-3">
                                            <div className="h-100 bg-light rounded p-3">
                                                <h3
                                                    className="text-cap text-muted mb-1.5"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover"
                                                    data-bs-title="Based on tracked transactions"
                                                >
                                                    Pending Queue
                                                </h3>
                                                <div className="fw-medium lh-1">
                                                    <span id="pendingcount">163788</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-xxl-3">
                                            <div className="h-100 bg-light rounded p-3">
                                                <h3
                                                    className="text-cap text-muted mb-1.5"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover"
                                                    data-bs-title="Based on last 5 blocks"
                                                >
                                                    Avg Block Size
                                                </h3>
                                                <div className="fw-medium lh-1">
                                                    <span id="avgtxnsperblock">150</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-xxl-3">
                                            <div className="h-100 bg-light rounded p-3">
                                                <h3
                                                    className="text-cap text-muted mb-1.5"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover"
                                                    data-bs-title="Based on last 5 blocks"
                                                >
                                                    Avg. Utilization
                                                </h3>
                                                <div className="fw-medium lh-1">
                                                    <span id="avgnetworkutilization">44.46%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="fs-sm text-muted mb-0">
                                        Last Refreshed: <span id="ct">Fri, 19 Jan 2024 10:26:44 UTC</span>
                                    </p>
                                </div>
                                <div>
                                    <h2 className="fs-base">Featured Actions</h2>
                                    <div className="table-responsive scrollbar-custom" style={{height: "300px"}}>
                                        <table className="table table-hover table-align-middle mb-0">
                                            <thead className="table-light text-nowrap" style={{top: "-1px"}}>
                                            <tr className="bg-light">
                                                <th scope="col" className="fw-normal">
                                                    Action
                                                </th>
                                                <th scope="col" className="fw-normal">
                                                    Low
                                                </th>
                                                <th scope="col" className="fw-normal">
                                                    Average
                                                </th>
                                                <th scope="col" className="fw-normal">
                                                    High
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="align-middle text-nowrap">
                                            <tr className="table-border-row">
                                                <td className="d-flex align-items-center gap-1.5">
                                                    <i
                                                        className="fa fa-question-circle text-muted"
                                                        data-bs-trigger="hover"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        aria-label="Estimated based on Gas Limit of 356190"
                                                    ></i>{" "}
                                                    <span>Swap</span>
                                                </td>
                                                <td>$19.39</td>
                                                <td>$19.39</td>
                                                <td>$19.39</td>
                                            </tr>
                                            <tr className="table-border-row">
                                                <td className="d-flex align-items-center gap-1.5">
                                                    <i
                                                        className="fa fa-question-circle text-muted"
                                                        data-bs-trigger="hover"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        aria-label="Estimated based on Gas Limit of 601953"
                                                    ></i>{" "}
                                                    <span>NFT Sale</span>
                                                </td>
                                                <td>$32.77</td>
                                                <td>$32.77</td>
                                                <td>$32.77</td>
                                            </tr>
                                            <tr className="table-border-row">
                                                <td className="d-flex align-items-center gap-1.5">
                                                    <i
                                                        className="fa fa-question-circle text-muted"
                                                        data-bs-trigger="hover"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        aria-label="Estimated based on Gas Limit of 114556"
                                                    ></i>{" "}
                                                    <span>Bridging</span>
                                                </td>
                                                <td>$6.24</td>
                                                <td>$6.24</td>
                                                <td>$6.24</td>
                                            </tr>
                                            <tr className="table-border-row">
                                                <td className="d-flex align-items-center gap-1.5">
                                                    <i
                                                        className="fa fa-question-circle text-muted"
                                                        data-bs-trigger="hover"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        aria-label="Estimated based on Gas Limit of 302169"
                                                    ></i>{" "}
                                                    <span>Borrowing</span>
                                                </td>
                                                <td>$16.45</td>
                                                <td>$16.45</td>
                                                <td>$16.45</td>
                                            </tr>
                                            <tr>
                                                <td className="d-flex align-items-center gap-1.5">
                                                    <i
                                                        className="fa fa-question-circle text-muted"
                                                        data-bs-trigger="hover"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        aria-label="Estimated based on Gas Limit entered by user."
                                                    ></i>
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        max="10000000"
                                                        className="form-control"
                                                        id="custom-gas-limit"
                                                        placeholder="Custom Gas Limit"
                                                    />
                                                </td>
                                                <td>
                                                    <span id="lowCustomGasCost"></span>
                                                </td>
                                                <td>
                                                    <span id="averageCustomGasCost"></span>
                                                </td>
                                                <td>
                                                    <span id="highCustomGasCost"></span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="bs-card h-100">
                            <div className="bs-card-body">
                                <ul
                                    className="nav nav-pills text-nowrap snap-x-mandatory overflow-x-auto scrollbar-custom flex-nowrap mb-1 pb-3 gap-2 nav_tabs1"
                                    id="nav_tabs_chart"
                                    role="tablist"
                                >
                                    <li className="nav-item snap-align-start" role="presentation">
                                        <a
                                            type="button"
                                            role="tab"
                                            className="nav-link active"
                                            id="heatmap_gasprice-tab"
                                            tabIndex={0}
                                            href="javascript:"
                                            data-bs-toggle="pill"
                                            data-bs-target="#heatmap_gasprice"
                                            aria-controls="nav_tabs_chart"
                                            aria-selected="true"
                                        >
                                            Gas Price Heatmap
                                        </a>
                                    </li>
                                    <li className="nav-item snap-align-start" role="presentation">
                                        <a
                                            type="button"
                                            role="tab"
                                            className="nav-link"
                                            id="chart_gasprice-tab"
                                            tabIndex={-1}
                                            href="javascript:"
                                            data-bs-toggle="pill"
                                            data-bs-target="#chart_gasprice"
                                            aria-controls="nav_tabs_chart"
                                            aria-selected="false"
                                        >
                                            Gas Price
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent_chart">
                                    <div className="tab-pane fade show active" id="heatmap_gasprice" role="tabpanel" aria-labelledby="heatmap_gasprice-tab">
                                        <h2 className="fs-base mb-0">Gas Price Heatmap</h2>
                                        <ChartGasPriceHeatMap/>
                                    </div>
                                    <div className="tab-pane fade show" id="chart_gasprice" role="tabpanel" aria-labelledby="chart_gasprice-tab">
                                        <h2 className="fs-base mb-0">7 Day Historical Oracle Gas Prices</h2>
                                        <ChartGasPrice/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className="nav nav-pills text-nowrap snap-x-mandatory overflow-x-auto flex-nowrap pt-3 pb-3 gap-2 nav_tabs1" id="nav_tabs" role="tablist">
                    <li className="nav-item snap-align-start" role="presentation">
                        <a
                            type="button"
                            role="tab"
                            className="nav-link active"
                            id="gasguzzler-tab"
                            tabIndex={0}
                            href="javascript:"
                            data-bs-toggle="pill"
                            data-bs-target="#gasguzzler"
                            aria-controls="historicaldata"
                            aria-selected="true"
                        >
                            <i className="fa fa-gas-pump me-1"></i>Gas Guzzlers
                        </a>
                    </li>
                    <li className="nav-item snap-align-start" role="presentation">
                        <a
                            type="button"
                            role="tab"
                            className="nav-link"
                            id="gassender-tab"
                            tabIndex={-1}
                            href="javascript:"
                            data-bs-toggle="pill"
                            data-bs-target="#gassender"
                            aria-controls="historicaldata"
                            aria-selected="false"
                        >
                            <i className="fa fa-gas-pump me-1"></i>Gas Spenders
                        </a>
                    </li>
                    <li className="nav-item snap-align-start" role="presentation">
                        <a
                            type="button"
                            role="tab"
                            className="nav-link"
                            id="historicaldata-tab"
                            tabIndex={-1}
                            href="javascript:"
                            data-bs-toggle="pill"
                            data-bs-target="#historicaldata"
                            aria-controls="historicaldata"
                            aria-selected="false"
                        >
                            <i className="fa fa-bars me-1"></i>Historical Gas Oracle Prices
                        </a>
                    </li>
                    <li className="nav-item snap-align-start" role="presentation">
                        <a
                            type="button"
                            role="tab"
                            className="nav-link"
                            id="costTxAction-tab"
                            tabIndex={-1}
                            href="javascript:"
                            data-bs-toggle="pill"
                            data-bs-target="#costTxAction"
                            aria-controls="costTxAction"
                            aria-selected="false"
                        >
                            <i className="fa fa-bars me-1"></i>Cost of Transaction Actions
                        </a>
                    </li>
                </ul>

                <div id="ContentPlaceHolder1_mainrow" className="bs-card">
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="gasguzzler" role="tabpanel" aria-labelledby="gasguzzler-tab">
                            <div className="bs-card-body">
                                <div className="d-md-flex justify-content-between">
                                    <p className="mb-2 mb-md-0">
                                        <b>Top 50 Gas Guzzlers</b> <span className="text-muted">(Contracts / Accounts that consume a lot of Gas)</span>
                                        <br/>
                                    </p>
                                    <nav aria-label="page navigation">
                                        <a
                                            target="_parent"
                                            href="/dashboards/gas-guzzler"
                                            className="btn btn-sm btn-secondary me-1"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="bottom"
                                            data-bs-title="Click to see Gas Guzzler Dashboard"
                                        >
                                            <i className="fa fa-chart-pie me-1"></i>Dashboard
                                        </a>
                                        <span className="badge bg-secondary bg-opacity-10 border border-secondary border-opacity-25 text-dark fw-medium px-2 lh-lg">
                                            Last updated at Block
                                            <a href="/block/19040069"> 19040069</a>
                                        </span>
                                    </nav>
                                </div>
                            </div>
                            <div className="table-responsive mb-2 mb-md-0">
                                <div id="mytable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div id="mytable_processing" className="dataTables_processing" style={{display: "none"}}>
                                        {/* <!-- Spinner --> */}
                                        <div id="overlay_mytable" className="text-center py-10 ">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            <div className="small text-muted mt-1">Loading</div>
                                        </div>
                                        {/* <!-- End Spinner --> */}
                                    </div>
                                    <table id="mytable" className="table table-hover table-align-middle mb-0 dataTable no-footer" role="grid">
                                        <thead className="text-nowrap table-border-row">
                                        <tr role="row">
                                            {/* <th scope="col" width={0} className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Rank" style={{width: "31.6354px"}}>Rank</th> */}
                                            <th scope="col" className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Rank" style={{width: "31.6354px"}}>
                                                Rank
                                            </th>
                                            <th scope="col" className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Address" style={{width: "318.208px"}}>
                                                Address
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting"
                                                tabIndex={0}
                                                aria-controls="mytable"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Fees Last 3hrs: activate to sort column descending"
                                                style={{width: "165.99px"}}
                                            >
                                                Fees Last 3hrs
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting_desc"
                                                tabIndex={0}
                                                aria-controls="mytable"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-sort="descending"
                                                aria-label="% Used 3hrs: activate to sort column ascending"
                                                style={{width: "96.6354px"}}
                                            >
                                                % Used 3hrs
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting"
                                                tabIndex={0}
                                                aria-controls="mytable"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Fees Last 24hrs: activate to sort column descending"
                                                style={{width: "189.656px"}}
                                            >
                                                Fees Last 24hrs
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting"
                                                tabIndex={0}
                                                aria-controls="mytable"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="% Used 24hrs: activate to sort column descending"
                                                style={{width: "105.562px"}}
                                            >
                                                % Used 24hrs
                                            </th>
                                            <th scope="col" className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Analytics" style={{width: "70.3125px"}}>
                                                Analytics
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="align-middle text-nowrap">
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span>🥇 1</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0x3fc91a3afd70395cd496c647d5a6cc9d4b2b7fad" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        Uniswap: Universal Router
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_1", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_1" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="103484.98">
                                                $103,484.98{" "}
                                                <span className="text-muted" title="Fees">
                                                    (41.82 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="13.65" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    13.65%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "100%"}}
                                                        aria-valuenow={13.65}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="1218387.51">
                                                $1,218,387.51{" "}
                                                <span className="text-muted" title="Fees">
                                                    (492.35 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="13.75">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    13.75%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0x3fc91a3afd70395cd496c647d5a6cc9d4b2b7fad#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span>🥈 2</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0xdac17f958d2ee523a2206206994597c13d831ec7" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        Tether: USDT Stablecoin
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xdAC17F958D2ee523a2206206994597C13D831ec7"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_2", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_2" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="41158.90">
                                                $41,158.90{" "}
                                                <span className="text-muted" title="Fees">
                                                    (16.63 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="5.12" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    5.12%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "38%"}}
                                                        aria-valuenow={5.12}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="475367.09">
                                                $475,367.09{" "}
                                                <span className="text-muted" title="Fees">
                                                    (192.10 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="5.02">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    5.02%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0xdac17f958d2ee523a2206206994597c13d831ec7#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span>🥉 3</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0x1c479675ad559dc151f6ec7ed3fbf8cee79582b6" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        Arbitrum: Sequencer Inbox
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x1c479675ad559DC151F6Ec7ed3FbF8ceE79582B6"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_3", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_3" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="18665.61">
                                                $18,665.61{" "}
                                                <span className="text-muted" title="Fees">
                                                    (7.54 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="2.55" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    2.55%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "19%"}}
                                                        aria-valuenow={2.55}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="206995.11">
                                                $206,995.11{" "}
                                                <span className="text-muted" title="Fees">
                                                    (83.65 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="2.33">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    2.33%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0x1c479675ad559dc151f6ec7ed3fbf8cee79582b6#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span> 4</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0xa0425d71cb1d6fb80e65a5361a04096e0672de03" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        zkSync Era: Validator Timelock 2
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xa0425d71cB1D6fb80E65a5361a04096E0672De03"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_4", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_4" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="21867.04">
                                                $21,867.04{" "}
                                                <span className="text-muted" title="Fees">
                                                    (8.84 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="2.46" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    2.46%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "18%"}}
                                                        aria-valuenow={2.46}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="149226.18">
                                                $149,226.18{" "}
                                                <span className="text-muted" title="Fees">
                                                    (60.30 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.60">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.60%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0xa0425d71cb1d6fb80e65a5361a04096e0672de03#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span> 5</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0x881d40237659c251811cec9c364ef91dc08d300c" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        Metamask: Swap Router
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x881D40237659C251811CEC9c364ef91dC08D300C"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_5", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_5" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="14989.49">
                                                $14,989.49{" "}
                                                <span className="text-muted" title="Fees">
                                                    (6.06 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="2.02" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    2.02%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "15%"}}
                                                        aria-valuenow={2.02}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="172262.96">
                                                $172,262.96{" "}
                                                <span className="text-muted" title="Fees">
                                                    (69.61 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.99">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.99%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0x881d40237659c251811cec9c364ef91dc08d300c#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span> 6</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0xf3de3c0d654fda23dad170f0f320a92172509127" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        0xF3dE3C...72509127
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xF3dE3C0d654FDa23daD170f0f320a92172509127"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_6", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_6" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="13030.75">
                                                $13,030.75{" "}
                                                <span className="text-muted" title="Fees">
                                                    (5.27 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.69" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.69%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "12%"}}
                                                        aria-valuenow={1.69}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="120955.96">
                                                $120,955.96{" "}
                                                <span className="text-muted" title="Fees">
                                                    (48.88 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.40">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.40%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0xf3de3c0d654fda23dad170f0f320a92172509127#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span> 7</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0x00000000000000adc04c56bf30ac9d3c0aaf14dc" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        Seaport 1.5
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_7", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_7" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="12084.45">
                                                $12,084.45{" "}
                                                <span className="text-muted" title="Fees">
                                                    (4.88 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.60" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.60%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "12%"}}
                                                        aria-valuenow={1.6}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="107067.52">
                                                $107,067.52{" "}
                                                <span className="text-muted" title="Fees">
                                                    (43.27 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.25">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.25%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0x00000000000000adc04c56bf30ac9d3c0aaf14dc#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span> 8</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0x7a250d5630b4cf539739df2c5dacb4c659f2488d" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        Uniswap V2: Router 2
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_8", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_8" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="13281.89">
                                                $13,281.89{" "}
                                                <span className="text-muted" title="Fees">
                                                    (5.37 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.52" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.52%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "11%"}}
                                                        aria-valuenow={1.52}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="196283.92">
                                                $196,283.92{" "}
                                                <span className="text-muted" title="Fees">
                                                    (79.32 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.81">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.81%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0x7a250d5630b4cf539739df2c5dacb4c659f2488d#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span> 9</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        Circle: USDC Token
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_9", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_9" className="fa fa-copy fa-fw "></i>
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="10573.05">
                                                $10,573.05{" "}
                                                <span className="text-muted" title="Fees">
                                                    (4.27 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.33" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.33%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "10%"}}
                                                        aria-valuenow={1.33}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="136922.37">
                                                $136,922.37{" "}
                                                <span className="text-muted" title="Fees">
                                                    (55.33 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.46">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.46%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even">
                                            <td align="right">
                                                <span> 10</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <i className="fa fa-file-alt text-secondary"></i>
                                                    <a href="/address/0xc9c747cce579959e53ba4cb5615d8783d4753450" data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                        0xc9C747...d4753450
                                                    </a>
                                                    <a
                                                        className="js-clipboard-guzzlers link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xc9C747CCe579959e53Ba4cb5615d8783d4753450"
                                                        data-bs-toggle-gg="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_guzzlers_10", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_guzzlers_10" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td data-bs-order="9785.19">
                                                $9,785.19{" "}
                                                <span className="text-muted" title="Fees">
                                                    (3.95 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.27" className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.27%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "9%"}}
                                                        aria-valuenow={1.27}
                                                        aria-valuemin={0}
                                                        aria-valuemax={13.65}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td data-bs-order="162154.40">
                                                $162,154.40{" "}
                                                <span className="text-muted" title="Fees">
                                                    (65.53 Eth){" "}
                                                </span>
                                            </td>
                                            <td data-bs-order="1.77">
                                                <span data-bs-trigger="hover" data-bs-toggle-gg="tooltip">
                                                    1.77%
                                                </span>
                                            </td>
                                            <td>
                                                <a href="/address/0xc9c747cce579959e53ba4cb5615d8783d4753450#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="bs-card-footer d-flex flex-wrap justify-content-between align-items-center gap-3 bottomdivdt">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="dataTables_length" id="mytable_length">
                                                <label className="d-flex align-items-center gap-2 text-muted">
                                                    Show rows:
                                                    <select name="mytable_length" aria-controls="mytable" className="form-select form-select-sm w-auto">
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="dataTables_paginate paging_full" id="mytable_paginate">
                                                <ul className="pagination pagination-sm mb-0">
                                                    <li className="paginate_button page-item first disabled" id="mytable_first">
                                                        <a href="#" aria-controls="mytable" data-dt-idx="0" tabIndex={0} className="page-link">
                                                            First
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item previous disabled" id="mytable_previous">
                                                        <a href="#" aria-controls="mytable" data-dt-idx="1" tabIndex={0} className="page-link px-3">
                                                            <i className="fa fa-chevron-left small"></i>
                                                        </a>
                                                    </li>
                                                    <li className="page-item disabled">
                                                        <span className="page-link">Page 1 of 4</span>
                                                    </li>
                                                    <li className="paginate_button page-item next" id="mytable_next">
                                                        <a href="#" aria-controls="mytable" data-dt-idx="2" tabIndex={0} className="page-link px-3">
                                                            <i className="fa fa-chevron-right small"></i>
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item last" id="mytable_last">
                                                        <a href="#" aria-controls="mytable" data-dt-idx="3" tabIndex={0} className="page-link">
                                                            Last
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="gassender" role="tabpanel" aria-labelledby="gassender-tab">
                            <div className="bs-card-body">
                                <div className="d-md-flex justify-content-between">
                                    <p className="mb-2 mb-md-0">
                                        <b>Top 50 Gas Spenders</b> <span className="text-muted">(Sending Accounts that pay a lot of Gas)</span>
                                        <br/>
                                    </p>
                                    <nav aria-label="page navigation"></nav>
                                </div>
                            </div>
                            <div className="table-responsive mb-2 mb-md-0" id="divTblResp2">
                                <div id="mytable2_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div id="mytable2_processing" className="dataTables_processing" style={{display: "none"}}>
                                        Processing...
                                    </div>
                                    <table id="mytable2" className="table table-hover table-align-middle mb-0 dataTable no-footer" role="grid">
                                        <thead className="text-nowrap table-border-row">
                                        <tr role="row">
                                            {/* <th scope="col" width="20" className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Rank">Rank</th> */}
                                            <th scope="col" style={{width: "20"}} className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Rank">
                                                Rank
                                            </th>
                                            <th scope="col" className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Address">
                                                Address
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting"
                                                tabIndex={0}
                                                aria-controls="mytable2"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Fees Last 3hrs: activate to sort column descending"
                                            >
                                                Fees Last 3hrs
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting_desc"
                                                tabIndex={0}
                                                aria-controls="mytable2"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-sort="descending"
                                                aria-label="% Spent 3hrs: activate to sort column ascending"
                                            >
                                                % Spent 3hrs
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting"
                                                tabIndex={0}
                                                aria-controls="mytable2"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Fees Last 24hrs: activate to sort column descending"
                                            >
                                                Fees Last 24hrs
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting"
                                                tabIndex={0}
                                                aria-controls="mytable2"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="% Spent 24hrs: activate to sort column descending"
                                            >
                                                % Spent 24hrs
                                            </th>
                                            <th scope="col" className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Analytics">
                                                Analytics
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="align-middle text-nowrap">
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span>🥇 1</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    <a href="/address/0xc1b634853cb333d3ad8663715b08f41a3aec47cc" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        Arbitrum: Batch Submitter
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xC1b634853Cb333D3aD8663715b08f41A3Aec47cc"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_1", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_1" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $18,665.61{" "}
                                                <span className="text-muted" title="Fees">
                                                    (7.54 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    2.55%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "100%"}}
                                                        aria-valuenow={2.55}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $206,995.11{" "}
                                                <span className="text-muted" title="Fees">
                                                    (83.65 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    2.33%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0xc1b634853cb333d3ad8663715b08f41a3aec47cc#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span>🥈 2</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x3527439923a63f8c13cf72b8fe80a77f6e572092" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        zkSync Era: Validator
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x3527439923a63F8C13CF72b8Fe80a77f6e572092"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_2", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_2" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $22,001.23{" "}
                                                <span className="text-muted" title="Fees">
                                                    (8.89 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    2.47%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "97%"}}
                                                        aria-valuenow={2.47}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $149,360.37{" "}
                                                <span className="text-muted" title="Fees">
                                                    (60.36 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    1.60%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x3527439923a63f8c13cf72b8fe80a77f6e572092#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span>🥉 3</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0xae2fc483527b8ef99eb5d9b44875f005ba1fae13" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        0xae2Fc4...ba1FaE13
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xae2Fc483527B8EF99EB5D9B44875F005ba1FaE13"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_3", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_3" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $63,525.45{" "}
                                                <span className="text-muted" title="Fees">
                                                    (25.67 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    1.23%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "48%"}}
                                                        aria-valuenow={1.23}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $566,395.10{" "}
                                                <span className="text-muted" title="Fees">
                                                    (228.88 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    1.19%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0xae2fc483527b8ef99eb5d9b44875f005ba1fae13#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span> 4</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x5050f69a9786f081509234f1a7f4684b5e5b76c9" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        Base: Batch Sender
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x5050F69a9786F081509234F1a7F4684b5E5b76C9"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_4", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_4" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $7,849.97{" "}
                                                <span className="text-muted" title="Fees">
                                                    (3.17 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    1.07%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "42%"}}
                                                        aria-valuenow={1.07}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $65,464.55{" "}
                                                <span className="text-muted" title="Fees">
                                                    (26.45 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.78%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x5050f69a9786f081509234f1a7f4684b5e5b76c9#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span> 5</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x6887246668a3b87f54deb3b94ba47a6f63f32985" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        Optimism: Batcher{" "}
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x6887246668a3b87F54DeB3b94Ba47a6f63F32985"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_5", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_5" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $7,307.48{" "}
                                                <span className="text-muted" title="Fees">
                                                    (2.95 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.99%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "39%"}}
                                                        aria-valuenow={0.99}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $107,196.49{" "}
                                                <span className="text-muted" title="Fees">
                                                    (43.32 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    1.18%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x6887246668a3b87f54deb3b94ba47a6f63f32985#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span> 6</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0xcf2898225ed05be911d3709d9417e86e0b4cfc8f" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        Scroll: Batch Committer
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xcF2898225ED05Be911D3709d9417e86E0b4Cfc8f"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_6", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_6" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $6,038.92{" "}
                                                <span className="text-muted" title="Fees">
                                                    (2.44 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.83%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "33%"}}
                                                        aria-valuenow={0.83}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $46,695.61{" "}
                                                <span className="text-muted" title="Fees">
                                                    (18.87 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.56%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0xcf2898225ed05be911d3709d9417e86e0b4cfc8f#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span> 7</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x9228624c3185fcbcf24c1c9db76d8bef5f5dad64" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        Linea: Operator
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x9228624C3185FCBcf24c1c9dB76D8Bef5f5DAd64"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_7", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_7" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $6,073.29{" "}
                                                <span className="text-muted" title="Fees">
                                                    (2.45 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.82%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "32%"}}
                                                        aria-valuenow={0.82}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $111,187.14{" "}
                                                <span className="text-muted" title="Fees">
                                                    (44.93 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    1.39%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x9228624c3185fcbcf24c1c9db76d8bef5f5dad64#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span> 8</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x12582a27e5e19492b4fcd194a60f8f5e1aa31b0f" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        0x12582A...1aa31B0F
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x12582A27E5e19492b4FcD194a60F8f5e1aa31B0F"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_8", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_8" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $5,193.37{" "}
                                                <span className="text-muted" title="Fees">
                                                    (2.10 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.74%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "29%"}}
                                                        aria-valuenow={0.74}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $19,130.71{" "}
                                                <span className="text-muted" title="Fees">
                                                    (7.73 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.30%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x12582a27e5e19492b4fcd194a60f8f5e1aa31b0f#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span> 9</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x16d5783a96ab20c9157d7933ac236646b29589a4" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        SHARP Blockchain Writer
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x16D5783a96ab20c9157d7933ac236646B29589A4"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_9", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_9" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $4,818.32{" "}
                                                <span className="text-muted" title="Fees">
                                                    (1.95 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.55%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "22%"}}
                                                        aria-valuenow={0.55}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $51,899.96{" "}
                                                <span className="text-muted" title="Fees">
                                                    (20.97 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.59%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x16d5783a96ab20c9157d7933ac236646b29589a4#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span> 10</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x337a81113f934b8522459f0ca207eee7aa0f4545" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        0x337a81...aA0f4545
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x337a81113F934B8522459f0CA207EEe7aA0f4545"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_10", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_10" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $4,234.55{" "}
                                                <span className="text-muted" title="Fees">
                                                    (1.71 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.49%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "19%"}}
                                                        aria-valuenow={0.49}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $23,336.57{" "}
                                                <span className="text-muted" title="Fees">
                                                    (9.43 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.26%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x337a81113f934b8522459f0ca207eee7aa0f4545#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span> 11</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0xeee64bc140c1122dfab2088f3e2e5e7e94732fbf" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        0xeee64b...94732fBf
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xeee64bC140C1122DFAb2088F3E2e5E7e94732fBf"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_11", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_11" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $3,339.65{" "}
                                                <span className="text-muted" title="Fees">
                                                    (1.35 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.47%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "18%"}}
                                                        aria-valuenow={0.47}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $3,339.65{" "}
                                                <span className="text-muted" title="Fees">
                                                    (1.35 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.06%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0xeee64bc140c1122dfab2088f3e2e5e7e94732fbf#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span> 12</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x7830c87c02e56aff27fa8ab1241711331fa86f43" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        Coinbase: Deposit
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x7830c87C02e56AFf27FA8Ab1241711331FA86F43"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_12", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_12" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $3,321.48{" "}
                                                <span className="text-muted" title="Fees">
                                                    (1.34 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.43%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "17%"}}
                                                        aria-valuenow={0.43}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $59,691.52{" "}
                                                <span className="text-muted" title="Fees">
                                                    (24.12 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.65%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x7830c87c02e56aff27fa8ab1241711331fa86f43#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td align="right">
                                                <span> 13</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0xd2c82f2e5fa236e114a81173e375a73664610998" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        CoinList 3
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0xD2C82F2e5FA236E114A81173e375a73664610998"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_13", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_13" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $2,958.64{" "}
                                                <span className="text-muted" title="Fees">
                                                    (1.20 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.40%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "16%"}}
                                                        aria-valuenow={0.4}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $28,490.88{" "}
                                                <span className="text-muted" title="Fees">
                                                    (11.51 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.31%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0xd2c82f2e5fa236e114a81173e375a73664610998#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td align="right">
                                                <span> 14</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x75e89d5979e4f6fba9f97c104c2f0afb3f1dcb88" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        MEXC: Mexc.com
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x75e89d5979E4f6Fba9F97c104c2F0AFB3F1dcB88"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_14", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_14" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $3,397.83{" "}
                                                <span className="text-muted" title="Fees">
                                                    (1.37 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.34%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "13%"}}
                                                        aria-valuenow={0.34}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $46,261.89{" "}
                                                <span className="text-muted" title="Fees">
                                                    (18.69 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.41%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x75e89d5979e4f6fba9f97c104c2f0afb3f1dcb88#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="odd">
                                            <td align="right">
                                                <span> 15</span>
                                            </td>
                                            <td>
                                                <span className="d-flex align-items-center gap-2">
                                                    {" "}
                                                    <a href="/address/0x6667961f5e9c98a76a48767522150889703ed77d" data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                        Mantle: Rollup Asserter
                                                    </a>
                                                    <a
                                                        className="js-clipboard-spenders link-secondary "
                                                        href="javascript:"
                                                        data-clipboard-text="0x6667961f5e9C98A76a48767522150889703Ed77D"
                                                        data-bs-toggle-gs="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-hs-clipboard-options='{ "type": "tooltip", "successText": "Copied!", "classChangeTarget": "#linkIcon_spenders_15", "defaultClass": "fa-copy", "successClass": "fa-check" }'
                                                        aria-label="Copy Address"
                                                    >
                                                        <i id="linkIcon_spenders_15" className="fa fa-copy fa-fw "></i>{" "}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                $2,422.56{" "}
                                                <span className="text-muted" title="Fees">
                                                    (0.98 Eth){" "}
                                                </span>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.34%
                                                </span>
                                                <div className="progress mt-1 me-5" style={{height: "2px"}}>
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{width: "13%"}}
                                                        aria-valuenow={0.34}
                                                        aria-valuemin={0}
                                                        aria-valuemax={2.55}
                                                    ></div>
                                                </div>
                                            </td>
                                            <td>
                                                $28,688.13{" "}
                                                <span className="text-muted" title="Fees">
                                                    (11.59 Eth){" "}
                                                </span>
                                            </td>
                                            <td>
                                                <span data-bs-trigger="hover" data-bs-toggle-gs="tooltip">
                                                    0.34%
                                                </span>{" "}
                                            </td>
                                            <td>
                                                <a href="/address/0x6667961f5e9c98a76a48767522150889703ed77d#analytics-txfees">
                                                    <i className="ms-2 fas fa-chart-line fa-lg"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="bs-card-footer d-flex flex-wrap justify-content-between align-items-center gap-3 bottomdivdt">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="dataTables_length" id="mytable2_length">
                                                <label className="d-flex align-items-center gap-2 text-muted">
                                                    Show rows:
                                                    <select name="mytable2_length" aria-controls="mytable2" className="form-select form-select-sm w-auto">
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="dataTables_paginate paging_full" id="mytable2_paginate">
                                                <ul className="pagination pagination-sm mb-0">
                                                    <li className="paginate_button page-item first disabled" id="mytable2_first">
                                                        <a href="#" aria-controls="mytable2" data-dt-idx="0" tabIndex={0} className="page-link">
                                                            First
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item previous disabled" id="mytable2_previous">
                                                        <a href="#" aria-controls="mytable2" data-dt-idx="1" tabIndex={0} className="page-link px-3">
                                                            <i className="fa fa-chevron-left small"></i>
                                                        </a>
                                                    </li>
                                                    <li className="page-item disabled">
                                                        <span className="page-link">Page 1 of 4</span>
                                                    </li>
                                                    <li className="paginate_button page-item next" id="mytable2_next">
                                                        <a href="#" aria-controls="mytable2" data-dt-idx="2" tabIndex={0} className="page-link px-3">
                                                            <i className="fa fa-chevron-right small"></i>
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item last" id="mytable2_last">
                                                        <a href="#" aria-controls="mytable2" data-dt-idx="3" tabIndex={0} className="page-link">
                                                            Last
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="tab-pane fade" id="historicaldata" role="tabpanel" aria-labelledby="historicaldata-tab">
                            <div className="bs-card-body">
                                <div className="d-md-flex justify-content-between">
                                    <p className="mb-2 mb-md-0">
                                        <b>Historical Data For Gas Oracle Prices</b>
                                    </p>
                                </div>
                            </div>
                            <div className="table-responsive mb-2 mb-md-0" id="divTblResp1">
                                <div id="mytable1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row">
                                        <div className="text-muted d-flex"></div>
                                        <div className="text-muted d-flex"></div>
                                    </div>
                                    <table id="mytable1" className="table table-hover table-align-middle mb-0 dataTable no-footer" role="grid">
                                        <thead className="text-nowrap table-border-row">
                                        <tr role="row">
                                            <th scope="col" className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Block">
                                                Block
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting_desc"
                                                tabIndex={0}
                                                aria-controls="mytable1"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-sort="descending"
                                                aria-label="Age: activate to sort column ascending"
                                            >
                                                Age
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting"
                                                tabIndex={0}
                                                aria-controls="mytable1"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Low Gas Price: activate to sort column ascending"
                                            >
                                                Low Gas Price
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting"
                                                tabIndex={0}
                                                aria-controls="mytable1"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Average Gas Price: activate to sort column ascending"
                                            >
                                                Average Gas Price
                                            </th>
                                            <th
                                                scope="col"
                                                className="sortable sorting"
                                                tabIndex={0}
                                                aria-controls="mytable1"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="High Gas Price: activate to sort column ascending"
                                            >
                                                High Gas Price
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr role="row" className="odd table-border-row">
                                            <td>
                                                <a href="/block/19040077">19040077</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:41:59">
                                                    47 secs ago
                                                </span>
                                            </td>
                                            <td>22 gwei</td>
                                            <td>22 gwei</td>
                                            <td>22 gwei</td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td>
                                                <a href="/block/19040076">19040076</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:41:47">
                                                    59 secs ago
                                                </span>
                                            </td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td>
                                                <a href="/block/19040075">19040075</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:41:35">
                                                    1 min ago
                                                </span>
                                            </td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                            <td>22 gwei</td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td>
                                                <a href="/block/19040074">19040074</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:41:23">
                                                    1 min ago
                                                </span>
                                            </td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                            <td>23 gwei</td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td>
                                                <a href="/block/19040073">19040073</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:41:11">
                                                    1 min ago
                                                </span>
                                            </td>
                                            <td>22 gwei</td>
                                            <td>22 gwei</td>
                                            <td>23 gwei</td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td>
                                                <a href="/block/19040072">19040072</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:40:59">
                                                    1 min ago
                                                </span>
                                            </td>
                                            <td>22 gwei</td>
                                            <td>22 gwei</td>
                                            <td>23 gwei</td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td>
                                                <a href="/block/19040072">19040072</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:40:59">
                                                    1 min ago
                                                </span>
                                            </td>
                                            <td>22 gwei</td>
                                            <td>22 gwei</td>
                                            <td>23 gwei</td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td>
                                                <a href="/block/19040071">19040071</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:40:47">
                                                    1 min ago
                                                </span>
                                            </td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                            <td>22 gwei</td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td>
                                                <a href="/block/19040070">19040070</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:40:35">
                                                    2 mins ago
                                                </span>
                                            </td>
                                            <td>22 gwei</td>
                                            <td>22 gwei</td>
                                            <td>23 gwei</td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td>
                                                <a href="/block/19040069">19040069</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:40:23">
                                                    2 mins ago
                                                </span>
                                            </td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                            <td>22 gwei</td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td>
                                                <a href="/block/19040068">19040068</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:40:11">
                                                    2 mins ago
                                                </span>
                                            </td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                            <td>23 gwei</td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td>
                                                <a href="/block/19040067">19040067</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:39:59">
                                                    2 mins ago
                                                </span>
                                            </td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                            <td>23 gwei</td>
                                        </tr>
                                        <tr role="row" className="odd table-border-row">
                                            <td>
                                                <a href="/block/19040067">19040067</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:39:59">
                                                    2 mins ago
                                                </span>
                                            </td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                            <td>23 gwei</td>
                                        </tr>
                                        <tr role="row" className="even table-border-row">
                                            <td>
                                                <a href="/block/19040066">19040066</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:39:47">
                                                    2 mins ago
                                                </span>
                                            </td>
                                            <td>20 gwei</td>
                                            <td>20 gwei</td>
                                            <td>20 gwei</td>
                                        </tr>
                                        <tr role="row">
                                            <td>
                                                <a href="/block/19040065">19040065</a>
                                            </td>
                                            <td className="sorting_1">
                                                <span data-bs-trigger="hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="2024-01-19 9:39:35">
                                                    3 mins ago
                                                </span>
                                            </td>
                                            <td>21 gwei</td>
                                            <td>21 gwei</td>
                                            <td>22 gwei</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="bs-card-footer d-flex flex-wrap justify-content-between align-items-center gap-3">
                                        <div className="text-muted d-flex">
                                            <div className="dataTables_length" id="mytable1_length">
                                                <label className="d-flex align-items-center gap-2 text-muted">
                                                    Show rows:
                                                    <select name="mytable1_length" aria-controls="mytable1" className="form-select form-select-sm w-auto">
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="pagination-sm d-flex">
                                            <div className="dataTables_paginate paging_full" id="mytable1_paginate">
                                                <ul className="pagination pagination-sm mb-0">
                                                    <li className="paginate_button page-item first disabled" id="mytable1_first">
                                                        <a href="#" aria-controls="mytable1" data-dt-idx="0" tabIndex={0} className="page-link">
                                                            First
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item previous disabled" id="mytable1_previous">
                                                        <a href="#" aria-controls="mytable1" data-dt-idx="1" tabIndex={0} className="page-link px-3">
                                                            <i className="fa fa-chevron-left small"></i>
                                                        </a>
                                                    </li>
                                                    <li className="page-item disabled">
                                                        <span className="page-link">Page 1 of 67</span>
                                                    </li>
                                                    <li className="paginate_button page-item next" id="mytable1_next">
                                                        <a href="#" aria-controls="mytable1" data-dt-idx="2" tabIndex={0} className="page-link px-3">
                                                            <i className="fa fa-chevron-right small"></i>
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item last" id="mytable1_last">
                                                        <a href="#" aria-controls="mytable1" data-dt-idx="3" tabIndex={0} className="page-link">
                                                            Last
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="costTxAction" role="tabpanel" aria-labelledby="costTxAction-tab">
                            <div className="table-responsive mb-2 mb-md-0">
                                <div id="mytable3_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="bs-card-body d-flex flex-wrap justify-content-between gap-2">
                                        <div className="divTitle">
                                            <b>Cost of Transaction Actions</b>
                                        </div>
                                        <div>
                                            <div id="mytable3_filter" className="dataTables_filter position-relative">
                                                <label>
                                                    <span className="d-flex align-items-center position-absolute top-0 bottom-0" style={{left: "0.5rem"}}>
                                                        <i className="fa fa-search fs-sm text-muted"></i>
                                                    </span>
                                                    <input
                                                        type="search"
                                                        className="form-control form-control-sm datatable-form-control bg-light bg-focus-white"
                                                        placeholder=""
                                                        aria-controls="mytable3"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="top d-flex flex-wrap align-items-center justify-content-between gap-3 topdivdt">
                                        <div className="datainfo"></div>
                                        <div className="d-flex flex-column gap-2">
                                            <div className="d-flex align-items-center align-self-sm-end"></div>
                                            <div className="d-sm-flex align-items-center align-self-sm-end"></div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table id="mytable3" className="table table-hover table-align-middle mb-0 dataTable no-footer" role="grid">
                                            <thead className="text-nowrap table-border-row">
                                            <tr role="row">
                                                <th className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Action">
                                                    Action
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sortable sorting"
                                                    tabIndex={0}
                                                    aria-controls="mytable3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Gas Limit: activate to sort column ascending"
                                                    style={{width: "15.5%"}}
                                                >
                                                    Gas Limit
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sortable sorting"
                                                    tabIndex={0}
                                                    aria-controls="mytable3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Low: activate to sort column ascending"
                                                    style={{width: "15.5%"}}
                                                >
                                                    Low
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sortable sorting"
                                                    tabIndex={0}
                                                    aria-controls="mytable3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Average: activate to sort column ascending"
                                                    style={{width: "15.5%"}}
                                                >
                                                    Average
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sortable sorting"
                                                    tabIndex={0}
                                                    aria-controls="mytable3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="High: activate to sort column ascending"
                                                    style={{width: "15.5%"}}
                                                >
                                                    High
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr role="row" className="odd table-border-row">
                                                <td>OpenSea: Sale</td>
                                                <td>71645</td>
                                                <td>$3.90</td>
                                                <td>$3.90</td>
                                                <td>$3.90</td>
                                            </tr>
                                            <tr role="row" className="even table-border-row">
                                                <td>Uniswap V3: Swap</td>
                                                <td>184523</td>
                                                <td>$10.05</td>
                                                <td>$10.05</td>
                                                <td>$10.05</td>
                                            </tr>
                                            <tr role="row" className="odd table-border-row">
                                                <td>USDT: Transfer</td>
                                                <td>54128</td>
                                                <td>$2.95</td>
                                                <td>$2.95</td>
                                                <td>$2.95</td>
                                            </tr>
                                            <tr role="row" className="even table-border-row">
                                                <td>SushiSwap: Swap</td>
                                                <td>141225</td>
                                                <td>$7.69</td>
                                                <td>$7.69</td>
                                                <td>$7.69</td>
                                            </tr>
                                            <tr role="row" className="odd table-border-row">
                                                <td>Curve: Swap</td>
                                                <td>183758</td>
                                                <td>$10.00</td>
                                                <td>$10.00</td>
                                                <td>$10.00</td>
                                            </tr>
                                            <tr role="row" className="even table-border-row">
                                                <td>Balancer: Swap</td>
                                                <td>196625</td>
                                                <td>$10.70</td>
                                                <td>$10.70</td>
                                                <td>$10.70</td>
                                            </tr>
                                            <tr role="row" className="odd table-border-row">
                                                <td>Bancor: Swap</td>
                                                <td>183193</td>
                                                <td>$9.97</td>
                                                <td>$9.97</td>
                                                <td>$9.97</td>
                                            </tr>
                                            <tr role="row" className="even table-border-row">
                                                <td>1inch: Swap</td>
                                                <td>141905</td>
                                                <td>$7.73</td>
                                                <td>$7.73</td>
                                                <td>$7.73</td>
                                            </tr>
                                            <tr role="row" className="odd table-border-row">
                                                <td>KyberSwap: Swap</td>
                                                <td>144389</td>
                                                <td>$7.86</td>
                                                <td>$7.86</td>
                                                <td>$7.86</td>
                                            </tr>
                                            <tr role="row" className="even table-border-row">
                                                <td>Uniswap V2: Swap</td>
                                                <td>152809</td>
                                                <td>$8.32</td>
                                                <td>$8.32</td>
                                                <td>$8.32</td>
                                            </tr>
                                            <tr role="row" className="odd table-border-row">
                                                <td>ERC20: Transfer</td>
                                                <td>65000</td>
                                                <td>$3.54</td>
                                                <td>$3.54</td>
                                                <td>$3.54</td>
                                            </tr>
                                            <tr role="row" className="even table-border-row">
                                                <td>ERC721: Transfer</td>
                                                <td>84904</td>
                                                <td>$4.62</td>
                                                <td>$4.62</td>
                                                <td>$4.62</td>
                                            </tr>
                                            <tr role="row" className="odd table-border-row">
                                                <td>CoW Protocol: Swap</td>
                                                <td>343353</td>
                                                <td>$18.69</td>
                                                <td>$18.69</td>
                                                <td>$18.69</td>
                                            </tr>
                                            <tr role="row" className="even table-border-row">
                                                <td>SuperRare: Sale</td>
                                                <td>130704</td>
                                                <td>$7.12</td>
                                                <td>$7.12</td>
                                                <td>$7.12</td>
                                            </tr>
                                            <tr role="row">
                                                <td>Rarible: Sale</td>
                                                <td>245730</td>
                                                <td>$13.38</td>
                                                <td>$13.38</td>
                                                <td>$13.38</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="bs-card-footer d-flex flex-wrap justify-content-between align-items-center gap-3 bottomdivdt">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="dataTables_length" id="mytable3_length">
                                                <label className="d-flex align-items-center gap-2 text-muted">
                                                    Show rows:
                                                    <select name="mytable3_length" aria-controls="mytable3" className="form-select form-select-sm w-auto">
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="dataTables_paginate paging_full" id="mytable3_paginate">
                                                <ul className="pagination pagination-sm mb-0">
                                                    <li className="paginate_button page-item first disabled" id="mytable3_first">
                                                        <a href="#" aria-controls="mytable3" data-dt-idx="0" tabIndex={0} className="page-link">
                                                            First
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item previous disabled" id="mytable3_previous">
                                                        <a href="#" aria-controls="mytable3" data-dt-idx="1" tabIndex={0} className="page-link px-3">
                                                            <i className="fa fa-chevron-left small"></i>
                                                        </a>
                                                    </li>
                                                    <li className="page-item disabled">
                                                        <span className="page-link">Page 1 of 2</span>
                                                    </li>
                                                    <li className="paginate_button page-item next" id="mytable3_next">
                                                        <a href="#" aria-controls="mytable3" data-dt-idx="2" tabIndex={0} className="page-link px-3">
                                                            <i className="fa fa-chevron-right small"></i>
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item last" id="mytable3_last">
                                                        <a href="#" aria-controls="mytable3" data-dt-idx="3" tabIndex={0} className="page-link">
                                                            Last
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <p className="d-flex align-items-baseline fs-sm text-muted gap-1 mb-3">
                        <i className="fa fa-lightbulb-on"></i>
                        <span>
                            Gas refers to the fee required to successfully conduct a transaction on the {process.env.REACT_APP_BASE_NAME} blockchain. Gas fees are paid in Ether
                            (ETH) and
                            denominated in Gwei. Learn more about Gas in our{" "}
                            <a target="_blank" href="https://info.etherscan.com/what-is-gas-fee/">
                                Knowledge Base
                            </a>
                            .
                        </span>
                    </p>
                </div>
            </section>
        </div>
    );
};

export default GasDetails;
