import React, {useEffect, useState} from 'react';
import useWallet from '../../../../common/hooks/useWallet';
import MetaMaskButton from '../index';
import AddressEntity from '../support-files/addressEntity';

const WalletInfo: React.FC = () => {
    const {addressHash, isWalletConnected} = useWallet();
    const [address, setAddress] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isWalletConnected && addressHash) {
            setAddress(addressHash);
        } else {
            setAddress(null);
        }
        setLoading(false);
    }, [isWalletConnected, addressHash]);

    return (
        <div style={{backgroundColor: isWalletConnected ? '#d4edda' : '#f8d7da'}} className="d-flex justify-content-between mb-3">
            <div className="d-flex align-items-center align-self-baseline ms-4">
                <div className="d-flex align-items-center">
                    {!loading && isWalletConnected ? (
                        <React.Fragment>
              <span className='me-3'>Connected:</span>
                            {address && (
                                <span className="me-3">
                                    <AddressEntity
                                        address={{hash: address}}
                                        truncation="dynamic"
                                        fontWeight={500}
                                    />
                                </span>
                            )}
                        </React.Fragment>
                    ) : (
                        <span className="me-3">{loading ? 'Loading...' : 'Disconnected'}</span>
                    )}
                    <MetaMaskButton/>
                </div>
            </div>
        </div>
    );
};

export default WalletInfo;