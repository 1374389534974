import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Project {
  projectId: number;
  name: string;
  track: string;
  icon: string;
  description: string;
  longDescription?: string;
  socialLinks: {
    x?: string;
    linkedin?: string;
    telegram?: string;
    github?: string;
  };
  siteLink: string;
  tags: string[];
}

const CustomThemedDapp: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<Project[]>([]);
  const [project, setProject] = useState<Project | null>(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `https://cdn.blocksscan.io/dapps/${process.env.REACT_APP_BASE_UNIT?.toLowerCase()}/projects.json`
        );
        const data: Project[] = await response.json();
        setProjects(data);

        const foundProject = data.find((p) => p.projectId === Number(projectId));
        setProject(foundProject || null);
      } catch (error) {
        console.error("Error fetching project data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [projectId]);

  if (loading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center bg-dark text-light">
        Loading...
      </div>
    );
  }

  if (!project) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center bg-dark text-light">
        Project not found
      </div>
    );
  }

  return (
    <div className="py-4">
      <div className="container">
        <div className="bs-card overflow-hidden">
          {/* Back Button */}
          <div className="m-3">
            <button
              onClick={() => window.history.back()}
              className="btn rounded-circle"
            >
              <i className="bi bi-arrow-left"></i>
            </button>
          </div>

          {/* Hero Banner */}
          <div className="p-4">
            <h1 className="display-4 fw-bold mb-0">{project.name}</h1>
            <p className="lead mb-0 text-light-50">{project.description}</p>
          </div>

          {/* Project Info */}
          <div className="p-4">
            {/* Project Icon and Title */}
            <div className="d-flex align-items-center gap-3 mb-4">
              <img
                src={project.icon}
                alt={project.name}
                className="rounded-circle"
                style={{ width: "60px", height: "60px" }}
              />
              <div>
                <h2 className="h5 mb-1">{project.name}</h2>
                <p className="text-light-50 mb-0">{project.description}</p>
              </div>
            </div>

            {/* Tags */}
            <div className="d-flex gap-2 mb-4">
              {project.tags.map((tag, index) => (
                <span key={index} className="badge bg-secondary">
                  {tag}
                </span>
              ))}
            </div>

            {/* Contract Address */}
            {project.siteLink && (
              <div className="d-flex align-items-center gap-2 mb-4">
                <span className="text-light-50">Website:</span>
                <a href={project.siteLink} target="_blank" rel="noopener noreferrer" className="text-light">
                  {project.siteLink}
                </a>
              </div>
            )}

            {/* Social Links */}
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3">
                {project.socialLinks.x && (
                  <a
                    href={`https://twitter.com/${project.socialLinks.x}`}
                    className="text-light text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bi bi-twitter fs-5"></i>
                  </a>
                )}
                {project.socialLinks.telegram && (
                  <a
                    href={`https://t.me/${project.socialLinks.telegram}`}
                    className="text-light text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bi bi-telegram fs-5"></i>
                  </a>
                )}
                {project.socialLinks.github && (
                  <a
                    href={`https://github.com/${project.socialLinks.github}`}
                    className="text-light text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bi bi-github fs-5"></i>
                  </a>
                )}
              </div>

              {/* CTA Button */}
              <a
                href={project.siteLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary px-4 rounded-pill"
              >
                Check it out
                <i className="bi bi-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomThemedDapp;
