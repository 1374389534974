import { ethers } from 'ethers';
import ERC20 from '../contracts/erc20/ERC20.json';
import { DeployTokenProps, DeployERC721Props} from '../types';
import ERC721JsonURI from '../contracts/erc721/JsonURI/ERC721.json';
import ERC721URI from '../contracts/erc721/URI/ERC721.json';
export const deployToken = async ({ name, symbol, supply, signer }: DeployTokenProps) => {
  try {
    const factory = new ethers.ContractFactory(ERC20.abi, ERC20.bytecode, signer);
    const contract = await factory.deploy(name, symbol, ethers.parseUnits(supply));
    await contract.deploymentTransaction()?.wait();
      return contract;
  } catch (err) {
    return Promise.reject(err);
  }
};
export const deployNFT = async ({ name, symbol, extension,uri,collectionSize,web3Provider }: DeployERC721Props) => {
  let ABI;
if(extension === 'with-json') {
  ABI = ERC721JsonURI;
} else if (extension === 'without-json') {
  ABI = ERC721URI;
} else {
  ABI = ERC721JsonURI;
}
  try {
    const signer = await web3Provider.getSigner();
    const factory = new ethers.ContractFactory(ABI.abi, ABI.bytecode, signer);
    const ERC721Contract = await factory.deploy(name, symbol, uri, collectionSize, { gasLimit: 5000000 });
    await ERC721Contract.deploymentTransaction()?.wait();
    return ERC721Contract;
  } catch (err) {
    return Promise.reject(err);
  }
}

export const validateAddress = (address: string) => {
  if (address === '') return false;
  return ethers.isAddress(address)
}
export const validateERC20MethodTypes = (method: string, args: string) => {
  const methodAbi = ERC20.abi.find(abi => abi.name === method);
  if (methodAbi === undefined) return false;
  if (methodAbi.inputs.length !== args.length) return false;
  return true;
};