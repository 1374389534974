import React, { FC, useEffect, useMemo, useState } from "react";
import BTable2 from "../../components/b-table2";
import {
  formatNumber,
  formatNumberWithSuffix,
  formatTimestamp,
  getFiat,
  getTimeLang,
  getFixed,
  formatUnit,
  toXDCNumber,
  truncate,
} from "../../common/utils/function";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import useCommon from "../../common/hooks/useCommon";
import AddressName from "../common/address-name";
import TokenImage from "../common/token-image";
import { useTranslation } from "react-i18next";
import BCopy from "../../components/b-copy";

type TokenAttribute = {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  image_url: string;
  coingecko_coin_id: string | null;
  websites: string[];
  description: string | null;
  gt_score: number;
  discord_url: string | null;
  telegram_handle: string | null;
  twitter_handle: string | null;
  categories: string[];
  gt_category_ids: string[];
};

type TokenInfo = {
  id: string;
  type: string;
  attributes: TokenAttribute;
};

const Pool: FC = () => {
  const { address } = useParams();
  const { getXDC, get0x } = useCommon();
  const { t, i18n } = useTranslation();
  const _stats = useSelector((e: Object) => (e as any)?.common?.stats);

  const [isUTC, setIsUTC] = useState(false);
  const [transactionList, setTransactionList] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    limit: 50,
  });
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo[]>([]);

  const handle = {
    pagination: (payload: any) => {
      setPagination(payload);
    },
  };
  if (!tokenInfo) {
    console.log("No Token Info for The Pool Tokens");
  }
  const getTrades = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `https://api.geckoterminal.com/api/v2/networks/${process.env.REACT_APP_BASE_UNIT?.toLowerCase()}/pools/${address}/trades`
      );

      const trades = response?.data?.data || [];
      if (Array.isArray(trades)) {
        setTransactionList(trades);
        setTotalRecords(trades.length);
      } else {
        console.warn("Trades response is not an array:", trades);
        setTransactionList([]);
      }
    } catch (error) {
      console.error("Error fetching trades:", error);
      setTransactionList([]);
    } finally {
      setLoading(false);
    }
  };
  const getPoolTokenInfo = async () => {
    try {
      setLoading(false);
      const response = await axios.get(
        `https://api.geckoterminal.com/api/v2/networks/xdc/pools/${address}/info`
      );
      const tokenInfo = response?.data?.data || [];
      setTokenInfo(tokenInfo);
      console.log(tokenInfo[0]);
    } catch (err) {
      console.log("Error in Fetching the Token Info", err);
    }
  };

  const handleButtonClick = () => {
    setIsUTC(!isUTC);
  };

  useEffect(() => {
    getTrades();
    getPoolTokenInfo();
  }, [pagination, _stats]);
  const header = useMemo(() => {
    return [
      {
        title: `${t("Tx Hash")}`,
        param: "hash",
        headerStyle: { minWidth: "130px" },
        render: (data: any) => (
          <React.Fragment>
            <Link to={`/tx/${get0x(data?.attributes?.tx_hash)}`}>
              {truncate(get0x(data?.attributes?.tx_hash))}
              &nbsp;
              <BCopy
                value={get0x(data?.attributes?.tx_hash)}
                tooltip={`${t("Transaction Hash")}`}
              />
            </Link>
          </React.Fragment>
        ),
      },
      {
        title: `${t("Block")}`,
        param: "block",
        tooltip: true,
        render: (data: any) => (
          <Link to={`/block/${data?.attributes?.block_number}`}>
            {data?.attributes?.block_number}
          </Link>
        ),
      },
      {
        title: (
          <a
            href="javascript:"
            data-bs-trigger="hover"
            onClick={handleButtonClick}
          >
            {isUTC ? t("Date Time (UTC)") : t("Age")}
          </a>
        ),
        param: "age",
        render: (data: any) =>
          isUTC
            ? formatTimestamp(data?.attributes?.block_timestamp)
            : getTimeLang(data?.attributes?.block_timestamp, t),
      },
      {
        title: "Type",
        param: "tradeType",
        render: (data: any) => (
          <span
            className={`text-capitalize ${
              data?.attributes?.kind === "buy" ? "text-success" : "text-danger"
            }`}
          >
            {data?.attributes?.kind}
          </span>
        ),
      },

      {
        title: `Price ${process.env.REACT_APP_BASE_UNIT}`,
        param: "priceXDC",
        render: (data: any) => (
          <span>{getFixed(data?.attributes?.price_to_in_currency_token)}</span>
        ),
      },
      {
        title: `Price USD`,
        param: "priceXDC",
        render: (data: any) => (
          <span>{getFiat(data?.attributes?.price_to_in_usd)}</span>
        ),
      },

      {
        title: `${tokenInfo[0]?.attributes?.symbol || "N/A"}`,
        param: "tokenAmount",
        render: (data: any) => (
          <span>
            {formatNumberWithSuffix(data?.attributes?.to_token_amount)}
          </span>
        ),
      },
      {
        title: ` Value `,
        param: "Value",
        render: (data: any) => (
          <span>{getFiat(data?.attributes?.volume_in_usd)}</span>
        ),
      },

      {
        title: "From",
        param: "txFrom",
        render: (data: any) => (
          <Link to={`/address/${data?.attributes?.tx_from_address}`}>
            {truncate(data?.attributes?.tx_from_address)}
            <BCopy
              value={get0x(data?.attributes?.tx_from_address)}
              tooltip={`${t("Tx From Address")}`}
            />
          </Link>
        ),
      },
    ];
  }, [pagination, _stats]);
  return (
    <React.Fragment>
      <div className="py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bs-page-title bs-font">Pool Dashboard</div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-3">
              <div className="bs-card p-3 h-100">
                <h2 className="text-uppercase text-secondary mb-0">
                  Transaction (24H)
                </h2>
               
                  <span className="fs-5 hoverClass">
                    {totalRecords} Transactions
               </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-3">
              <div className="bs-card p-3 h-100">
                <h2 className="text-uppercase text-secondary mb-0">
                  Token Name
                </h2>
                <span className="fs-5">
                  {tokenInfo[0]?.attributes?.name || "N/A"}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-3">
              <div className="bs-card p-3 h-100">
                <h2 className="text-uppercase text-secondary mb-0">
                  Token Symbol
                </h2>
                <span className="fs-5">
                  {tokenInfo[0]?.attributes?.symbol || "N/A"}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-3">
              <div className="bs-card p-3 h-100">
                <h2 className="text-uppercase text-secondary mb-0">
               GT Score
                </h2>
                <span className="fs-5">
                  {tokenInfo[0]?.attributes?.decimals
                    ? `${getFixed(
                        tokenInfo[0]?.attributes?.gt_score)}`
                    : "N/A"}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="bs-tab-content">
                <BTable2
                  title={
                    <React.Fragment>
                      <p className="mb-0 font-b">
                        Total {totalRecords || 0} records found <br />
                        <span className="ms-1 text-muted">
                          (Showing the last 10,000 records){" "}
                        </span>
                      </p>
                    </React.Fragment>
                  }
                  header={header}
                  list={transactionList || []}
                  loading={loading}
                  pagination={pagination}
                  totalRecords={totalRecords}
                  onPagination={handle.pagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pool;
