import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { profileAccountService } from "../../../store/actions";

interface TransactionNote {
  tag_name: string;
  transaction_hash: string;
}

interface ApiResponse {
  addressDetails: TransactionNote[];
}

interface AccountData {
  message: string;
  signature: string;
}

const TransactionPrivateTags: React.FC = () => {
  const [notes, setNotes] = useState<TransactionNote[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  
  const account = useSelector((state: any) => state?.common?.account);
  
  const fetchNotes = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const data: AccountData = {
        message: account?.message,
        signature: account?.signature
      };
      
      const response: ApiResponse = await profileAccountService.getTxPrivateNotes(data);
      setNotes(response.addressDetails || []);
      setError(null);
    } catch (err) {
      console.error('Error fetching transaction notes:', err);
      setError('Failed to load private notes');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const handleEdit = (note: TransactionNote): void => {
    // Implement edit functionality
    console.log('Edit note:', note);
  };

  const handleDelete = (note: TransactionNote): void => {
    // Implement delete functionality
    console.log('Delete note:', note);
  };

  const renderNote = (note: TransactionNote, index: number) => (
    <tr key={`${note.transaction_hash}-${index}`} className="bs-table-row">
      <td className="bs-table-cell">
        <div className="d-flex align-items-center">
          <a 
            href={`/tx/${note.transaction_hash}`} 
            className="text-truncate text-primary"
            style={{ maxWidth: "200px" }}
          >
            {note.transaction_hash}
          </a>
        </div>
      </td>
      <td className="bs-table-cell">
        <div className="d-flex align-items-center">
          <span className="text-truncate" style={{ maxWidth: "200px" }}>
            {note.tag_name}
          </span>
          <button 
            className="btn btn-sm btn-ghost-secondary ms-2" 
            title="Edit"
            onClick={() => handleEdit(note)}
          >
            <i className="bi bi-pencil"></i>
          </button>
          <button 
            className="btn btn-sm btn-ghost-danger ms-1" 
            title="Delete"
            onClick={() => handleDelete(note)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </td>
    </tr>
  );

  if (isLoading) {
    return (
      <div className="text-center py-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-4 text-danger">
        <i className="bi bi-exclamation-circle me-2"></i>
        {error}
      </div>
    );
  }

  if (!notes.length) {
    return (
      <div className="text-center py-24">
        <div className="mb-4 mt-4">
          <span 
            className="bg-warning bg-opacity-10 border border-warning border-opacity-25 text-yellow-600 fs-70x content-center rounded-pill mx-auto"
            style={{ width: "4rem", height: "4rem" }}
          >
            <i className="bi bi-inboxes fs-4"></i>
          </span>
        </div>
        <h3 className="h5">There are no private notes found</h3>
        <p className="text-muted mb-0">Please try again later</p>
      </div>
    );
  }

  return (
    <tbody>
      {notes.map((note, index) => renderNote(note, index))}
    </tbody>
  );
};

export default TransactionPrivateTags;