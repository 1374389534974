export const tokenInfo = [
  {
    address: "", // Native token
    name: "XDC",
    type: "NATIVE",
    symbol: "XDC",
    decimals: 18,
    maxLimit:1,
  },
  {
    address: "0x5310f5934172D55E9E7C55f7E9070c458609F106",
    name: "FAUCETTEST",
    type: "ERC20",
    symbol: "FAUCET",
    decimals: 18,
    maxLimit:1,
  },
];

interface Explorer {
  [key: string]: {
    [key: string]: string;
  };
}

interface APIResponse {
  explorer: Explorer;
}

export const fetchNetworks = async () => {
  try {
    const response = await fetch(
      "https://cdn.blocksscan.io/eaas/config/XDC.json"
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch networks: ${response.statusText}`);
    }
    const data = await response.json();

    const networks = Object.entries(data.explorer).map(([key, value]) => {
      const label = Object.keys(value as Object)[0];
      return {
        value: key,
        label,
      };
    });

    return networks;
  } catch (error) {
    console.error("Error fetching networks:", error);
    return [];
  }
};

// Call the function to test

export const networks = [
  { value: "apothem", label: "Apothem Testnet" },
  { value: "devnet", label: "Devnet" },
  { value: "mainnet", label: "Mainnet" },
];
export const apothemTokenInfo = [
  {
    address: "",
    name: "XDC",
    type: "NATIVE",
    symbol: "XDC",
    decimals: 18,
    maxLimit: 100,
  },
  {
    address: "0x5310f5934172D55E9E7C55f7E9070c458609F106",
    name: "FAUCETTEST",
    type: "ERC20",
    symbol: "FAUCET",
    decimals: 18,
    maxLimit: 10,
  },
  {
    address: "0x13b51C04dbBE2a1D6bDF82dE746E9cc9F1E316ed", // Example NFT contract
    name: "MYNFT",
    type: "ERC721",
    symbol: "XDCNFT",
    maxLimit: 1,
  },
];

export const devnetTokenInfo = [
  {
    address: "", // Native token
    name: "DevXDC",
    type: "NATIVE",
    symbol: "DEVXDC",
    decimals: 18,
    maxLimit: 30,
  },
  {
    address: "0xABCDEF123456789...",
    name: "DEVFAUCETTEST",
    type: "ERC20",
    symbol: "DEVFAUCET",
    decimals: 18,
    maxLimit: 80,
  },
  {
    address: "0xNFT123456789...", // Example NFT contract
    name: "Dev Test NFT",
    type: "ERC721",
    symbol: "DEVNFT",
    maxLimit: 2,
  },
];
const array = [
  {
    address: "",
    name: "XDC",
    type: "NATIVE",
    symbol: "XDC",
    decimals: 18,
    maxLimit: 50,
  },
  {
    address: "0xdf29cb40cb92a1b8e8337f542e3846e185deff96",
    name: "FATHOM",
    type: "ERC20",
    symbol: "FXD",
    decimals: "18",
    maxLimit: "5",
  },
  {
    address: "0x5310f5934172D55E9E7C55f7E9070c458609F106",
    name: "FAUCETTEST",
    type: "ERC20",
    symbol: "FAUCET",
    decimals: 18,
    maxLimit: 100,
  },
  {
    address: "0x13b51C04dbBE2a1D6bDF82dE746E9cc9F1E316ed",
    name: "MYNFT",
    type: "ERC721",
    symbol: "XDCNFT",
    maxLimit: 1,
  },
  { address: "", name: "", type: "", symbol: "", decimals: "", maxLimit: "" },
  {address:""}
];
